<template>
  <v-list-item
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <v-list-item-title class="pr-4">
      {{ item.name }}
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: 'BusStopItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    mouseEnter() {
      this.$emit('onOver', this.item);
    },
    mouseLeave() {
      this.$emit('onOut', this.item);
    },
  },
}
</script>

<style scoped>

</style>
