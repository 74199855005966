<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card class="fill-height">
      <v-layout column fill-height>
        <v-flex shrink>
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title>
              Маршрут {{ item.name }}
            </v-toolbar-title>

            <v-spacer/>

            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="onClose"
              >
                Закрыть
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-flex>

        <v-flex style="height: 100px">
          <v-layout class="fill-height">
            <v-flex shrink class="fill-height" style="overflow: auto;">
              <div style="width: 300px;" class="fill-height">
                <v-list v-if="loaded">
                  <template v-if="bus_stops.length">
                    <h3 class="pa-4">Остановки маршрута</h3>

                    <template v-for="(stop, index) in bus_stops">
                      <BusStopItem
                        :item="stop"
                        :key="index"
                      ></BusStopItem>

                      <v-divider
                        :key="`${index}_divider`"
                        class="mx-4"
                      ></v-divider>
                    </template>
                  </template>

                  <template v-else>
                    <p class="pa-4">
                      На маршруте еще не добавлены остановки
                    </p>
                  </template>
                </v-list>

                <div v-else class="pa-4 text-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </div>
            </v-flex>

            <v-flex style="width: 100px">
              <div class="fill-height" id="route_map"></div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
// Компоненты
import BusStopItem from './BusStopItem';

// Сервисы
import MapLayersService from '@/services/MapLayersService';
import ShowRouteMapService from '@/components/services/ShowRouteMapService';

// Utils
import Map from '@/utils/map';

export default {
  name: 'Route',
  components: {
    BusStopItem,
  },
  props: {
    collection: {
      type: Object,
      required: true,
    },
  },
  created() {
    // fetch remote URL to get route info
    // get list of bus stops
    this.item = this.collection.master.selected;

    let s = document.createElement('link');

    s.setAttribute('rel', 'stylesheet');
    s.setAttribute('href', 'https://api.tiles.mapbox.com/mapbox-gl-js/v1.5.0/mapbox-gl.css');
    document.getElementsByTagName('head')[0].appendChild(s);

    s = document.createElement('script');
    s.setAttribute('src', 'https://api.tiles.mapbox.com/mapbox-gl-js/v1.5.0/mapbox-gl.js');
    document.getElementsByTagName('head')[0].appendChild(s);

    s.onload = () => {
      Map.init({
        container: document.getElementById('route_map'),
      }).then(() => {
        ShowRouteMapService.init(Map);

        MapLayersService.getRoutePoint(this.item.id)
          .then(data => {
            const route = {
              id: this.item.vcroutesId,
              points: data,
            };

            this.busStops = _copy(data.filter(it => it.name !== null));

            ShowRouteMapService.drawRoute(
              _copy(route),
              _copy(this.busStops)
            );
          })
          .finally(() => {
            this.loaded = true;
          });
      });
    };
  },
  data: () => ({
    dialog: true,
    loaded: false,
    item: null,
    bus_stops: [],
  }),
  methods: {
    onClose() {
      this.dialog = !this.dialog;
    },
    // highlightMarker(item) {
    //   console.log(item.name, 'over');
    // },
    // unHighlightMarker(item) {
    //   console.log(item.name, 'out');
    // }
  }
}
</script>

<style scoped>

</style>
